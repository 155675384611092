@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-family: Raleway, Roboto, system-ui, sans-serif;
    font-size: 20px;
  }
  body {
    @apply text-sm bg-ecday2023-background text-ecday2023-text;
  }
  * {
    @apply text-ecday2023-text;
  }
}
.Toastify__toast-container.Toastify__toast-container {
  @apply w-full lg:w-9/12;
}
.wrapper {
  @apply flex flex-col lg:col-start-2 lg:col-span-10 self-center bg-white px-2 lg:px-0 py-4 lg:py-8 rounded bg-transparent gap-4;
  .title {
    @apply mb-2 lg:mb-4 text-32px lg:text-40px font-semibold border-b uppercase;
  }
  form,
  .form-like {
    @apply p-4 mb-4 lg:mb-8 shadow-sm lg:shadow-md bg-gray-300;
  }
  input,
  textarea,
  select {
    @apply mb-4 border border-gray-300 p-2;
  }
}

.list {
  @apply flex flex-col lg:min-h-[250px] xl:min-h-[300px] col-span-3 self-center bg-white px-2 pt-2 pb-4 rounded bg-white border border-gray-300 hover:scale-105 cursor-pointer transition duration-300;
  &:has(.selected) {
    @apply border-ecdayred;
  }
  h2 {
    @apply mt-2 border-t pt-2;
  }
  a {
    @apply flex flex-col text-center;
  }
  .picture {
    @apply my-0 mx-auto flex;
  }
}

.nav {
  @apply flex justify-between mt-2 lg:mt-4;
  &-items {
    @apply button;
  }
}

.main-content,
.main-nav {
  @apply grid lg:grid-cols-1 lg:grid-cols-12 m-auto w-full;
}

.main-nav {
  @apply border-b border-gray-300 bg-white px-2 lg:px-0;
  nav {
    @apply flex flex-col lg:flex-row;
  }
  a:not(.logo) {
    @apply border-b-2 border-white hover:border-section py-2 lg:py-4 hover:text-section;
  }
}

.plate-container {
  @apply border bg-white h-[70vh] overflow-x-hidden mb-4 border-gray-300;
  .slate-HeadingToolbar {
    @apply bg-white p-0 m-0 w-full border-0 border-b border-black;
  }
  .plate-input {
    @apply h-[85%] p-2;
  }
}

.p-fileupload {
  @apply flex flex-col justify-center;
  &-content {
    @apply mt-2;
    div:not(.p-fileupload-row) + div:not(.p-fileupload-row) {
      @apply flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4;
    }
  }
  &-buttonbar {
    @apply flex space-x-4;
    svg {
      @apply mr-4;
    }
  }
  .p-progressbar {
    @apply hidden;
  }
  &-row {
    @apply speeches-card;
    .file-info {
      @apply flex flex-col flex-grow;
    }
    .file-size {
      @apply mx-0 text-16px;
    }
    .p-button {
      @apply ml-4;
    }
  }
  .p-button {
    @apply flex content-center align-middle items-center;
    cursor: pointer;
    &.p-disabled {
      @apply invisible;
    }
    input {
      @apply hidden;
    }
  }
}

.form {
  &-fields {
    @apply flex flex-col lg:flex-row;
  }
  &-fields__col {
    @apply flex flex-col flex-1 border-gray-600 border-b lg:border-b-0 mb-8 lg:mb-0 pb-16px lg:pb-0;
  }
  &-fields__upload {
    @apply w-full lg:w-[400px] h-[300px] lg:h-[480px] lg:ml-4 py-0 relative justify-start bg-gray-600 lg:bg-white;
    .user-profile-picture {
      @apply w-full lg:w-[400px] h-[200px] lg:h-[400px] absolute;
    }
    .p-fileupload {
      @apply p-0;
    }
    .p-fileupload-buttonbar {
      @apply flex-col absolute w-full bottom-0 bg-white z-20 border-t py-2 space-x-0;
      order: 2;
      svg {
        @apply mx-4;
      }
    }
    .p-fileupload-row {
      @apply p-0 flex-col max-w-full lg:max-w-[400px] mx-auto relative border-0;
      .p-button {
        @apply px-0 ml-0 border-0 absolute top-0 right-0 bg-white w-[30px] h-[30px] justify-center;
      }
    }
    .file-info {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @apply w-full lg:w-[400px] h-[200px] lg:h-[400px] text-left;
    }
    .p-fileupload-content {
      @apply mt-0;
      div + div {
        @apply mx-0;
      }
    }
  }
}
.has-error {
  @apply border-ecday2023-600;
}

.button {
  @apply transition-colors duration-300 ease-in shadow-sm cursor-pointer uppercase self-end text-center text-white cursor-pointer py-2 px-2 lg:px-10 font-normal text-20px leading-5 border-2 border-ecday2023-text bg-ecday2023-text active:bg-ecday2023-text active:text-white hover:bg-white hover:text-ecday2023-text;
  &-primary {
    @apply border-section bg-section active:bg-section active:text-white hover:bg-white hover:text-section;
  }
  &-secondary {
    @apply border-sky-600 bg-sky-600 active:bg-sky-600 active:text-white hover:bg-white hover:text-sky-700;
  }
  &__centered {
    @apply self-center;
  }
}

.speeches {
  &-table {
    td,
    th {
      @apply px-2 py-4 lg:p-4 text-left;
    }
    tr {
      @apply cursor-pointer;
      &:hover {
        @apply bg-gray-200;
      }
    }
  }
  &-container {
    .status-pill {
      @apply p-2 leading-tight bg-gray-50;
      &.approved {
        @apply text-green-700 bg-green-100;
      }
      &.informed {
        @apply text-sky-700 bg-sky-100;
      }
    }
  }
  &-card {
    @apply bg-gray-50 border rounded-sm lg:rounded-lg shadow p-2 flex-grow items-center flex;
  }
  &-card__children a {
    @apply break-all;
  }
}

.partner-wrap {
  @apply lg:col-span-12 flex flex-row bg-white columns-4 p-2 xl:pt-4 max-w-full flex-wrap;
}
.partners {
  @apply flex flex-col xl:flex-row flex-grow pb-0 xl:pb-4 basis-full xl:basis-auto;
}

.partner-list {
  @apply xl:border-r border-b xl:border-b-0 px-0 lg:px-2 py-2 xl:py-0 align-middle border-gray-300 md:basis-1 xl:basis-1/4 flex flex-col;
  &.last {
    @apply border-0 lg:border-t xl:border-0;
  }
  &.gold {
    @apply col-span-2 xl:basis-full lg:border-b-0;
  }
  &.main {
    @apply pl-0;
  }
  &.organizers {
    @apply pr-0 flex-wrap flex-grow pt-2 xl:pt-0;
  }
  &.main,
  &.platinum,
  &.organizers {
    .row img {
      @apply 2xl:max-w-[240px] max-w-[160px] block;
    }
  }
  h2 {
    @apply my-0 mx-auto uppercase text-ecdayred mb-4 text-sm 2xl:text-base font-semibold;
  }
  .row {
    @apply flex m-auto flex-wrap justify-center;
    img {
      @apply max-w-[130px] 2xl:max-w-[160px] block;
    }
    &.first {
      @apply mb-0 md:mb-2 pb-2 md:pb-0;
      img {
        @apply max-w-[110px] 2xl:max-w-[130px] block;
      }
    }
  }
}
